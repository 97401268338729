import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import config from "./config";

const LOCALES_DIR = config.env.locales_dir || "locales";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    backend: {
      loadPath: `/${LOCALES_DIR}/{{lng}}/{{ns}}.json`, // the path to your translation files
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

//https://hygraph.com/blog/react-internationalization
