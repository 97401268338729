import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RideDataService from "../../services/rides";
import UserContext from "../../contexts/UserContext";
import config from "../../config";
import { useNavigate } from "react-router-dom";

import {
  getStatusString,
  getPaidString,
  getPaymentString,
  getCodeString,
} from "../utils/valuesHelper.js";
import { formatDate } from "../utils/dateUtils";
import { truncateText, capitalizeFirstLetter } from "../utils/someFunction";

import {
  EllipsisHorizontalIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import {
  Menu,
  MenuButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";

import { XMarkIcon } from "@heroicons/react/24/outline";

const TableBookings = () => {
  const primaryColor = config.env.primaryColor || "#00df9a";
  const sortOptions = [
    { name: "Newest (ride)", value: "newestRide", href: "#" },
    { name: "Oldest (ride)", value: "oldestRide", href: "#" },
    { name: "Newest (creation)", value: "newestCreation", href: "#" },
    { name: "Oldest (creation)", value: "oldestCreation", href: "#" },
  ];
  const filters = [
    {
      id: "status",
      name: "Status",
      options: [
        { value: 0, label: "To validate" },
        { value: 1, label: "Validated" },
        { value: 2, label: "Assigned" },
        { value: 3, label: "Canceled" },
        { value: 4, label: "Deleted" },
        { value: 5, label: "Done" },
        { value: 6, label: "Start" },
        { value: 7, label: "Issue" },
      ],
    },
    {
      id: "paid",
      name: "Payment",
      options: [
        { value: 0, label: "To pay" },
        { value: 1, label: "Paid" },
        { value: 2, label: "Deposit" },
      ],
    },
    {
      id: "destination",
      name: "Destination",
      options: [
        { value: "bru", label: "Bru" },
        { value: "crl", label: "CRL" },
      ],
    },
    {
      id: "departure",
      name: "From",
      options: [
        { value: "bru", label: "Bru" },
        { value: "crl", label: "CRL" },
      ],
    },
  ];

  const [open, setOpen] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions[0].name
  );
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    paid: [],
    destination: [],
    departure: [],
  });

  const handleFilterChange = (sectionId, value) => {
    setSelectedFilters((prevSelectedFilters) => {
      const sectionFilters = prevSelectedFilters[sectionId];
      if (sectionFilters.includes(value)) {
        return {
          ...prevSelectedFilters,
          [sectionId]: sectionFilters.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevSelectedFilters,
          [sectionId]: [...sectionFilters, value],
        };
      }
    });
  };

  function classNames_2(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const statuses_paid = {
    0: "text-red-700 bg-red-50 ring-red-600/10",
    1: "text-green-700 bg-green-50 ring-green-600/20",
    2: "text-gray-600 bg-gray-50 ring-gray-500/10",
    3: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  };
  const statuses_ride = {
    0: "text-green-600 bg-green-50 ring-green-600/10",
    1: "text-green-700 bg-green-50 ring-green-500/20",
    2: "text-blue-600 bg-blue-50 ring-blue-500/10",
    3: "text-orange-700 bg-orange-50 ring-orange-600/20",
    4: "text-red-700 bg-red-50 ring-red-600/20",
    5: "text-green-500 bg-green-50 ring-green-500/20",
    6: "text-blue-600 bg-blue-50 ring-blue-600/20",
    7: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  };

  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [rides, setRides] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Filtered data

  const filterAndSortData = useCallback(() => {
    let filtered = rides;

    // Apply filters
    Object.keys(selectedFilters).forEach((key) => {
      const values = selectedFilters[key];
      if (values.length > 0) {
        filtered = filtered.filter((item) => values.includes(item[key]));
      }
    });

    // Apply sorting
    console.log("selectedSortOption", selectedSortOption);
    const sorted = [...filtered].sort((a, b) => {
      switch (selectedSortOption) {
        case "newestRide":
          return new Date(b.date) - new Date(a.date);
        case "oldestRide":
          return new Date(a.date) - new Date(b.date);
        case "newestCreation":
          return new Date(b.created_at) - new Date(a.created_at);
        case "oldestCreation":
          return new Date(a.created_at) - new Date(b.created_at);
        default:
          return 0;
      }
    });

    setFilteredData(sorted);
  }, [rides, selectedFilters, selectedSortOption]);

  useEffect(() => {
    // Filter the data whenever selectedFilters changes
    filterAndSortData();
  }, [selectedFilters, selectedSortOption, filterAndSortData]);

  function edit_bookink(id) {
    navigate(`/booking/${id}/edit`);
  }

  const retrieveRides = useCallback(() => {
    if (user.role === 1) {
      RideDataService.getAll()
        .then((response) => {
          console.log(response.data);
          setRides(response.data.rides);
          setFilteredData(response.data.rides);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (user.role === 2) {
      console.log("get driver rides");
      RideDataService.getDriverAll(user.id)
        .then((response) => {
          console.log(response.data);
          setRides(response.data.rides);
          setFilteredData(response.data.rides);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [user.role, user.id]);

  useEffect(() => {
    retrieveRides();
  }, [retrieveRides]);

  return (
    <div className="">
      {/* Mobile filter dialog */}
      <Dialog className="relative z-40 sm:hidden" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-white bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 z-40 flex">
          <DialogPanel
            transition
            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-black py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
          >
            <div className="flex items-center justify-between px-4">
              <h2 className="text-lg font-medium text-gray-300">Filters</h2>
              <button
                type="button"
                className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-black p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="bg-black h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Filters */}
            <form className="mt-4">
              {filters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.name}
                  className="border-t border-gray-200 px-4 py-6"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-mx-2 -my-3 flow-root ">
                        <DisclosureButton className="flex w-full items-center justify-between bg-black px-2 py-3 text-sm text-gray-400">
                          <span className="font-medium text-gray-300">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            <ChevronDownIcon
                              className={classNames_2(
                                open ? "-rotate-180" : "rotate-0",
                                "h-5 w-5 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </DisclosureButton>
                      </h3>
                      <DisclosurePanel className="pt-6">
                        <div className="space-y-6">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="bg-black flex items-center"
                            >
                              <input
                                id={`filter-mobile-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                checked={selectedFilters[section.id].includes(
                                  option.value
                                )}
                                onChange={() =>
                                  handleFilterChange(section.id, option.value)
                                }
                                className="h-4 w-4 rounded bg-gray-800 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                className="ml-3 text-sm text-gray-300"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              ))}
            </form>
          </DialogPanel>
        </div>
      </Dialog>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold leading-6 text-white">
            {t("DashboardPage.title")}
          </h1>
          <p className="mt-2 text-base text-gray-300">
            {t("DashboardPage.message")}
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-0 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-0">
          <div className="pt-5 mx-5 lg:mx-0">
            {user.role === 1 && (
              <div className="flex mt-4 sm:ml-5 sm:mt-0 sm:flex-none pb-5">
                <button
                  disabled
                  type="button"
                  className="disabled:text-slate-500 block rounded-md px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  style={{ backgroundColor: primaryColor }}
                >
                  {t("DashboardPage.button_add")}
                </button>
                <button
                  type="button"
                  className="block rounded-md mx-3 px-3 py-2 text-center text-sm font-semibold text-black hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  style={{ backgroundColor: primaryColor }}
                >
                  <Link to="/users" className="block text-inherit">
                    {t("DashboardPage.button_u_list")}
                  </Link>
                </button>
                <button
                  type="button"
                  className="block rounded-md px-3 py-2 text-center text-sm font-semibold text-black hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  style={{ backgroundColor: primaryColor }}
                >
                  <Link to="/drivers" className="block text-inherit">
                    {t("DashboardPage.button_d_list")}
                  </Link>
                </button>
                <button
                  disabled
                  type="button"
                  className="disabled:text-slate-500 block rounded-md mx-3 px-3 py-2 text-center text-sm font-semibold text-black enabled:hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  style={{ backgroundColor: primaryColor }}
                >
                  {t("DashboardPage.button_pass")}
                </button>
              </div>
            )}
            {/* Filter Section */}
            <div
              aria-labelledby="filter-heading"
              className="border-t border-gray-200 py-6"
            >
              <h2 id="filter-heading" className="sr-only">
                Ride filters
              </h2>

              <div className="flex items-center justify-between">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="group inline-flex justify-center text-sm font-medium text-gray-300 hover:text-gray-700">
                      Sort
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-500 group-hover:text-gray-400"
                        aria-hidden="true"
                      />
                    </MenuButton>
                  </div>

                  <MenuItems
                    transition
                    className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-gray-800 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="py-1">
                      {sortOptions.map((option) => (
                        <MenuItem key={option.value}>
                          {({ focus }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                focus ? "bg-gray-700" : "",
                                "block px-4 py-2 text-sm font-medium text-gray-300"
                              )}
                              onClick={() =>
                                setSelectedSortOption(option.value)
                              }
                            >
                              {option.name}
                            </a>
                          )}
                        </MenuItem>
                      ))}
                    </div>
                  </MenuItems>
                </Menu>

                <button
                  type="button"
                  className="inline-block text-sm font-medium text-gray-300 hover:text-gray-700 sm:hidden"
                  onClick={() => setOpen(true)}
                >
                  Filters
                </button>

                <PopoverGroup className="hidden sm:flex sm:items-baseline sm:space-x-8">
                  {filters.map((section, sectionIdx) => (
                    <Popover
                      as="div"
                      key={section.name}
                      id={`desktop-menu-${sectionIdx}`}
                      className="relative inline-block text-left"
                    >
                      <div>
                        <PopoverButton className="group inline-flex items-center justify-center text-sm font-medium text-gray-300 hover:text-gray-700">
                          <span>{section.name}</span>
                          {selectedFilters[section.id].length > 0 ? (
                            <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                              {selectedFilters[section.id].length}
                            </span>
                          ) : null}
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-500 group-hover:text-gray-4S00"
                            aria-hidden="true"
                          />
                        </PopoverButton>
                      </div>

                      <PopoverPanel
                        transition
                        className="bg-gray-800 absolute right-0 z-10 mt-2 origin-top-right rounded-md p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <form className="space-y-4 ">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center "
                            >
                              <input
                                id={`filter-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                checked={selectedFilters[section.id].includes(
                                  option.value
                                )}
                                onChange={() =>
                                  handleFilterChange(section.id, option.value)
                                }
                                className="h-4 w-4 bg-gray-500 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-300"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </form>
                      </PopoverPanel>
                    </Popover>
                  ))}
                </PopoverGroup>
              </div>
            </div>
            <ul className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 pt-5">
              {filteredData.map((ride, index) => (
                <li
                  key={ride._id}
                  className="overflow-hidden rounded-xl border border-gray-200 cursor-pointer hover:bg-gray-800 hover:text-white"
                  onClick={() => edit_bookink(ride._id)}
                >
                  <div className=" flex items-center gap-x-4 border-b border-gray-900/5 bg-black p-6 ">
                    <div className="text font-medium leading-6 text-white">
                      {ride.ride_code ? truncateText(ride.ride_code, 15) : ""}
                    </div>
                    <Menu as="div" className="relative ml-auto">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </Menu>
                  </div>

                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dd className="text-gray-500">
                        {ride.lastname && `${ride.lastname} ${ride.firstname}`}
                        {" | "} {ride.email && ride.email}
                        {" | "}
                        {ride.countrycode ? "+" + ride.countrycode : "NA"}{" "}
                        {ride.phone ? ride.phone : "NA"}
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dd className="text-gray-500">
                        {ride.departure !== "NA" &&
                          `${t(t("Hero.between_2"))}: ${t(
                            getCodeString(ride.departure)
                          )}`}
                        {ride.destination !== "NA" &&
                          `${t(t("Hero.between_1"))}: ${t(
                            getCodeString(ride.destination)
                          )}`}
                        ({ride.passengers})
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-300">
                        {t("FormType.passengers")}
                      </dt>
                      <dd className="text-gray-500">
                        <time dateTime={ride.date}>{ride.passengers}</time>
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-300">
                        {t("MainForm.date")} & {t("MainForm.time")}
                      </dt>
                      <dd className="text-gray-500">
                        <time dateTime={ride.date}>
                          {formatDate(ride.date)} {ride.time}
                        </time>
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-300">{t("Table.status")}</dt>
                      <dd
                        className={classNames(
                          statuses_ride[ride.status],
                          "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                        )}
                      >
                        {t(getStatusString(ride.status))}
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-300">{t("Table.driver")}</dt>
                      <dd className="text-gray-500">
                        {capitalizeFirstLetter(ride.driver?.lastname)}{" "}
                        {capitalizeFirstLetter(ride.driver?.firstname)}
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-300">{t("MainForm.pricing")}</dt>
                      <dd className="flex items-start gap-x-2">
                        <div className="font-medium text-gray-500">
                          {ride.price}€ (
                          {t(getPaymentString(ride.payment_mode))}) {"-> "}
                          {ride.driver_price}€
                        </div>
                        <div
                          className={classNames(
                            statuses_paid[ride.paid],
                            "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                          )}
                        >
                          {t(getPaidString(ride.paid))}
                        </div>
                      </dd>
                    </div>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableBookings;
