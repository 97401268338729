import React from "react";
import Condition from "../components/Condition";
import config from "../config";

const Terms = () => {
 const termsData = config.termsData;

  return (
    <div className="w-full py-16 px-4 text-white">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Terms and Conditions
        </h1>
        <p className="lg:text-2xl md:text-xl text-base">
          "{config.appName}" acts as an agent for booking activities. "
          {config.appName}" provides searching and booking of activities on its
          website. "{config.appName}" works with local operator. The contract
          for the activities is concluded between You
          and the Provider.
        </p>
      </div>
      
      <div className="max-w-[1240px] mx-auto mt-5">
        {termsData.map((item, index) => (
            <Condition
              key={index}
              title_number={item.title_number}
              title={item.title}
              items={item.items}
            />
        ))}
      </div>
    </div>
  );
};

export default Terms;
