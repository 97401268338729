import React from "react";
import Condition from "../components/Condition";
import config from "../config";

const Privacy = () => {
  
  return (
    <div className="w-full py-16 px-4 text-white">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Privacy Data Policy
        </h1>
        <p className="lg:text-2xl md:text-xl text-base">
          This page outlines how we handle your personal information and
          safeguard your privacy when you use our service. It is crucial for you
          to read and understand this policy, as it contains important details
          about your rights and obligations. The policy is divided into various
          sections, each detailing a specific aspect of our privacy practices,
          from the types of information we collect to how we use and protect it.
          By using our service, you consent to the practices described in this
          policy.
        </p>
      </div>
      <div className="max-w-[1240px] mx-auto mt-5">
        <Condition
          title_number="X"
          title="Privacy Data Policy"
          items={config.privacyDataItems}
        />
      </div>
      <div className="max-w-[1240px] mx-auto mt-5">
        <p className="text-sm">Last update on 01/06/2024</p>
      </div>
    </div>
  );
};

export default Privacy;
