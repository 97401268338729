import React, { useEffect } from "react";
import Tables from "../components/Admin/Drivers";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import config from "../config";
const Drivers = () => {
  const { t } = useTranslation();
  const primaryColor = config.env.primaryColor || "#00df9a";
  //const { user } = useContext(UserContext);
  const location = useLocation();

  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    if (location.state?.message) {
      successToast(location.state.message);
    }
  }, [location]);

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <div className="bg-gray-800 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight" style={{ color: primaryColor }}>
                <Link to={`/dashboard`} className="hover:text-indigo-400">
                  {t("Navbar.dashboard")}
                </Link>
                {" / "} {t("DriversPage.link")}
              </h1>
            </div>
          </header>
        </div>

        <div className="bg-gray-800 -mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-black px-5 py-6 shadow sm:px-6">
              <Tables />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drivers;
