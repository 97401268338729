import React from 'react'
import Hero from '../components/Hero';
import AboutUs from '../components/AboutUs';
import HowTo from '../components/HowTo';
import CTA from '../components/CTA';
import FAQlist from '../components/FAQ/FAQlist';

const Home = () => {
    return (
        <div>
            <Hero />
            <AboutUs />
            <HowTo />
            <CTA />
            <FAQlist />
        </div>
    )
}

export default Home
