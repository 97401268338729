import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const Newsletter = () => {
  const { t } = useTranslation();
  const [participantsType, setParticipantsType] = useState(""); // Default to empty string
  const [numParticipants, setNumParticipants] = useState("Indifferent"); // Default to 1
  const [format, setFormat] = useState(""); // Default to empty string

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "participantsType") {
      setParticipantsType(value);
    } else if (name === "numParticipants") {
      setNumParticipants(value);
    } else if (name === "format") {
      setFormat(value);
    }
  };

  const primaryColor = config.env.primaryColor || "#00df9a";

  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-4">
        <div className="lg:col-span-3 my-2">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("CTA.main")}
          </h1>
          <p>{t("CTA.subtitle")}</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col items-center justify-between w-full">
            {/* Participants Type Selection */}
            <select
              name="participantsType"
              onChange={handleInputChange}
              value={participantsType}
              className="mt-3 p-3 w-full rounded-md text-black"
            >
              <option value="DEFAULT" disabled>
                {t("MainForm.participantsType")}
              </option>
              <option value="couple">{t("MainForm.couple")}</option>
              <option value="family">{t("MainForm.family")}</option>
              <option value="friends">{t("MainForm.friends")}</option>
              <option value="group">{t("MainForm.group")}</option>
              <option value="individual">{t("MainForm.individual")}</option>
              <option value="community">{t("MainForm.community")}</option>
              <option value="company">{t("MainForm.company")}</option>
            </select>

            {/* Number of Participants */}
              <select
                id="numParticipants"
                name="numParticipants"
                value={numParticipants}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="">{t("MainForm.select")}</option>
                <option value="Indifferent">{t("MainForm.indifferent")}</option>
                <option value="1 to 10">{t("MainForm.participants1to10")}</option>
                <option value="+10">{t("MainForm.participants10")}</option>
                <option value="+20">{t("MainForm.participants20")}</option>
                <option value="+30">{t("MainForm.participants30")}</option>
              </select>

            {/* Format Selection */}
            <select
                id="format"
                name="format"
                value={format}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="Stay">{t("MainForm.stay")}</option>
                <option value="Seminars">{t("MainForm.seminars")}</option>
                <option value="Activity">{t("MainForm.activity")}</option>
                <option value="TeamBuilding">{t("MainForm.teamBuilding")}</option>
              </select>
          </div>
        </div>
      </div>
      <div className="max-w-[1240px] mx-auto">
        <Link
          to={`/booking?participantsType=${participantsType}&numParticipants=${numParticipants}&format=${format}`}
        >
          <button
            className="w-full rounded-md font-medium my-6 mx-auto py-3 text-black enabled:hover:bg-indigo-400 disabled:text-slate-500" style={{ backgroundColor: primaryColor }}
          >
            {t("Button.cta")}
          </button>
        </Link>
        <p>
          {t("CTA.note")}{" "}
          <Link to="/privacy">
            <span className="" style={{ color: primaryColor }}>{t("CTA.link")}.</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Newsletter;
