import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import countries from "../utils/countries";
import { useTranslation } from "react-i18next";
import { CheckboxSection, RadioSection } from "./FormSections"; 
import { getFormattedDate } from "../utils/dateUtils";
import config from "../../config";

const BooKingFormComponent = ({ participantsType = "", numParticipants = "", format = "Stay" }) => {
  const primaryColor = config.env.primaryColor || "#00df9a";
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datePicker, setDatePicker] = useState({
    startDate: new Date().toISOString().split("T")[0], // Format as YYYY-MM-DD,
    endDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split("T")[0], // Format as YYYY-MM-DD,
  });
  
  const navigate = useNavigate();
  
  const successToast = (message) => {
    toast.success(message, { position: toast.POSITION.TOP_CENTER });
  };
  
  const errorToast = (message) => {
    toast.warning(message, { position: toast.POSITION.TOP_CENTER });
  };

  const locationOptions = ["France", "Spain", "Italy", "Greece", "Switzerland", "Portugal", "Others"];
  const categoryOptions = ["Sports activities", "Nature stay", "teambuildingcohesion", "Cultural stay", "Wellness stay", "Multi activity", "Others"];
  const durationOptions = ["2d1n", "3d2n", "5d4n", "Half day", "Full day", "Stay", "Weekend"];
  const physicalLevelOptions = ["Easy", "Moderate", "Athletic", "Intense"];
  const priceOptions = ["0 to 50 €", "50 to 70 €", "70 to 100 €", "+100 €"];
  const interestsOptions = ["Adventure", "Relaxation", "Cultural", "Gastronomy", "Sports", "Family", "Others"];


  const [formData, setFormData] = useState({
    participantsType: participantsType,
    numParticipants: numParticipants,
    format: format,
    dateFrom: datePicker.startDate,
    dateTo: datePicker.endDate,
    interests: [],
    location: [],
    category: [],
    duration: [],
    physicalLevel: "",
    price: "",
    firstname: "",
    lastname: "",
    email: "",
    countrycode: "",
    phone: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
    
  };

  // Handle Datepicker changes
  const handleDatePickerValueChange = (newValue) => {
    setDatePicker(newValue);
    setFormData({
      ...formData,
      dateTo: newValue.startDate,
      dateFrom: newValue.endDate,
    });
    console.log(newValue);
    console.log(formData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const errors = validateForm(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const message = `
        Participants: ${formData.participantsType}\n
        Number of Participants: ${formData.numParticipants}\n
        Format: ${formData.format}\n
        Dates: From ${formData.dateFrom} to ${formData.dateTo}\n
        Interests: ${formData.interests.join(", ")}\n
        Location: ${formData.location.join(", ")}\n
        Category: ${formData.category.join(", ")}\n
        Duration: ${formData.duration.join(", ")}\n
        Physical Level: ${formData.physicalLevel}\n
        Price: ${formData.price}\n
        First Name: ${formData.firstname}\n
        Last Name: ${formData.lastname}\n
        Email: ${formData.email}\n
        Country Code: ${formData.countrycode}\n
        Phone: ${formData.countrycode} ${formData.phone}\n
        Comments: ${formData.comments || "N/A"}
      `;

      const emailData = {
        to_name: `${formData.firstname} ${formData.lastname}`,
        to_email: formData.email,
        to_bcc: process.env.REACT_APP_EMAILJS_BCC,
        message: message,
      };

      try {
        await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          emailData,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        );

        successToast(t("toast.success"));
        setTimeout(() => {
          navigate("/thankyou");
        }, 3000);
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        errorToast(t("toast.failure"));
      }
    } else {
      setIsSubmitting(false);
      errorToast(t("We can not contact the server."));
    }
  };

  const validateForm = (inputValues) => {
    let errors = {};

    if (!inputValues.participantsType) {
      errors.participantsType = t("MainForm.errorParticipantsType");
    }

    if (!inputValues.numParticipants) {
      errors.numParticipants = t("MainForm.errorNumParticipants");
    }

    if (!inputValues.dateFrom || !inputValues.dateTo) {
      errors.dateFrom = t("MainForm.errorDate");
    }

    if (!inputValues.firstname) {
      errors.firstname = t("MainForm.errorFirstname");
    }

    if (!inputValues.lastname) {
      errors.lastname = t("MainForm.errorLastname");
    }

    if (!inputValues.email) {
      errors.email = t("MainForm.errorEmail");
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = t("MainForm.invalidEmail");
    }

    if (!inputValues.countrycode) {
      errors.countrycode = t("MainForm.errorCountryCode");
    }

    if (!inputValues.phone) {
      errors.phone = t("MainForm.errorPhone");
    }

    return errors;
  };

  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4">
      <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-white">{t("MainForm.title")}</h1>
      <form onSubmit={handleSubmit} className="space-y-12">
        {/* Participants Section */}
        <div className="border-b border-white/10 pb-12">
          <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
            {t("MainForm.participantsTitle")}
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="participantsType" className="block text-xl font-medium text-white">{t("MainForm.participantsLabel")}</label>
              <select
                id="participantsType"
                name="participantsType"
                value={formData.participantsType}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="">{t("MainForm.select")}</option>
                <option value="couple">{t("MainForm.couple")}</option>
                <option value="family">{t("MainForm.family")}</option>
                <option value="friends">{t("MainForm.friends")}</option>
                <option value="alone">{t("MainForm.alone")}</option>
                <option value="group">{t("MainForm.group")}</option>
                <option value="individual">{t("MainForm.individual")}</option>
                <option value="community">{t("MainForm.community")}</option>
                <option value="company">{t("MainForm.company")}</option>
              </select>
              {formErrors.participantsType && <p className="text-red-600">{formErrors.participantsType}</p>}
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="numParticipants" className="block text-xl font-medium text-white">{t("MainForm.numParticipants")}</label>
              <select
                id="numParticipants"
                name="numParticipants"
                value={formData.numParticipants}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="">{t("MainForm.select")}</option>
                <option value="indifferent">{t("MainForm.indifferent")}</option>
                <option value="1 to 10">{t("MainForm.participants1to10")}</option>
                <option value="+10">{t("MainForm.participants10")}</option>
                <option value="+20">{t("MainForm.participants20")}</option>
                <option value="+30">{t("MainForm.participants30")}</option>
              </select>
            </div>
          </div>
        </div>

        {/* Format Section */}
        <div className="border-b border-white/10 pb-12">
          <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">{t("MainForm.formatTitle")}</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="format" className="block text-xl font-medium text-white">{t("MainForm.format")}</label>
              <select
                id="format"
                name="format"
                value={formData.format}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              >
                <option value="stay">{t("MainForm.stay")}</option>
                <option value="seminars">{t("MainForm.seminars")}</option>
                <option value="activity">{t("MainForm.activity")}</option>
                <option value="teamBuilding">{t("MainForm.teamBuilding")}</option>
              </select>
            </div>
          </div>
        </div>

        {/* Reservation Dates */}
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="dateFrom" className="block text-xl font-medium text-white">
              {t("MainForm.booking_dates")}
            </label>
            <Datepicker
              value={datePicker}
              onChange={(newValue) => handleDatePickerValueChange(newValue)}
              asSingle={false}
              minDate={new Date()}
              primaryColor={"green"}
              className="dark:bg-gray-800 dark:text-white"
            />
          </div>
          {formErrors.date && <p className="text-red-600">{formErrors.date}</p>}
        </div>

        <div>
          {/* Interests Section */}
          <CheckboxSection title="MainForm.interestsTitle" items={interestsOptions} formKey="interests" handleChange={handleChange}/>

          {/* Location Section */}
          <CheckboxSection title="MainForm.locationTitle" items={locationOptions} formKey="location" handleChange={handleChange}/>

          {/* Category Section */}
          <CheckboxSection title="MainForm.categoryTitle" items={categoryOptions} formKey="category" handleChange={handleChange}/>

          {/* Duration Section */}
          <CheckboxSection title="MainForm.durationTitle" items={durationOptions} formKey="duration" handleChange={handleChange}/>

          {/* Physical Level Section */}
          <CheckboxSection title="MainForm.physicalLevelTitle" items={physicalLevelOptions} formKey="physicalLevel" handleChange={handleChange}/>

          {/* Price Section */}
          <RadioSection title="MainForm.priceTitle" items={priceOptions} formKey="price" handleChange={handleChange}/>
        </div>

        {/* First Name, Last Name, Email, Phone, and Comments */}
        <div className="border-b border-white/10 pb-12">
          <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">{t("MainForm.yourInfoTitle")}</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="firstname" className="block text-xl font-medium text-white">{t("MainForm.firstname")}</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {formErrors.firstname && <p className="text-red-600">{formErrors.firstname}</p>}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastname" className="block text-xl font-medium text-white">{t("MainForm.lastname")}</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {formErrors.lastname && <p className="text-red-600">{formErrors.lastname}</p>}
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-xl font-medium text-white">{t("MainForm.email")}</label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {formErrors.email && <p className="text-red-600">{formErrors.email}</p>}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="countrycode" className="block text-xl font-medium text-white">{t("MainForm.countryCode")}</label>
              <select
                id="countrycode"
                name="countrycode"
                value={formData.countrycode}
                onChange={handleChange}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
              >
                {countries.map(([country, _, __, code], index) => (
                  <option key={index} value={code}>
                    {`${country} (+${code})`}
                  </option>
                ))}
              </select>
              {formErrors.countrycode && <p className="text-red-600">{formErrors.countrycode}</p>}
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="phone" className="block text-xl font-medium text-white">{t("MainForm.phone")}</label>
              <input
                id="phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              {formErrors.phone && <p className="text-red-600">{formErrors.phone}</p>}
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="comments" className="block text-xl font-medium text-white">{t("MainForm.comments")}</label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 bg-white/5 py-2 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6 mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="w-full rounded-md active:bg-indigo-700 px-16 py-2 text-xl font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:text-slate-500"
              style={{ backgroundColor: primaryColor }}
              disabled={isSubmitting}
            >
              {t("MainForm.submit")}
            </button>
          </div>

          {Object.keys(formErrors).length > 0 && (
            <p className="sm:col-span-6 text-xl leading-6 text-red-600">
              {t("MainForm.errorMessages")}
            </p>
          )}
        </div>
      </form>

      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default BooKingFormComponent;
