import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Booking from "./pages/Booking";
import ScrollToTop from "./components/ScrollToTop";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Dashboard from "./pages/Dashboard";
import Drivers from "./pages/Drivers";
import Driver from "./pages/Driver";
import EditBooking from "./pages/EditBooking";
import Login from "./pages/Login";
import CheckRide from "./pages/CheckRide";
import ThankYouPage from "./pages/ThankYouPage";

import SecureRoute from "./components/Auth/SecureRoute"; // <-- Import the SecureRoute
import UserProvider from "./components/UserProvider";
import Users from "./pages/Users";
import User from "./pages/User";

import config from "./config";

function App() {
  return (
    <Suspense fallback={<div>Loading translations...</div>}>
      <Helmet>
        <title>
          {config.appName} - {config.appTitle}
        </title>
        <meta name="description" content={config.appDescription} />
        <meta name="keywords" content={config.appKeyWords} />
        <link rel="icon" href={`/${config.appFavicon}`} />
        {`<!-- Google tag (gtag.js) -->`}
        {config.googleTag && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.googleTagId}`}
          ></script>
        )}
        {config.googleTag && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${config.googleTagId}');`}
          </script>
        )}
      </Helmet>
      <BrowserRouter>
        <UserProvider>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/check" element={<CheckRide />} />
            <Route path="/thankyou" element={<ThankYouPage />} />

            <Route
              path="/dashboard"
              element={
                <SecureRoute>
                  <Dashboard />
                </SecureRoute>
              }
            />
            <Route
              path="/drivers"
              element={
                <SecureRoute>
                  <Drivers />
                </SecureRoute>
              }
            />
            <Route
              path="/driver/:id/edit"
              element={
                <SecureRoute>
                  <Driver />
                </SecureRoute>
              }
            />
            <Route
              path="/driver/create"
              element={
                <SecureRoute>
                  <Driver />
                </SecureRoute>
              }
            />

            <Route
              path="/users"
              element={
                <SecureRoute>
                  <Users />
                </SecureRoute>
              }
            />
            <Route
              path="/user/:id/edit"
              element={
                <SecureRoute>
                  <User />
                </SecureRoute>
              }
            />
            <Route
              path="/user/create"
              element={
                <SecureRoute>
                  <User />
                </SecureRoute>
              }
            />

            <Route
              path="/booking/:id/edit"
              element={
                <SecureRoute>
                  <EditBooking />
                </SecureRoute>
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </UserProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
