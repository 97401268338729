// termsData.js

export const termsData = [
    {
      title: "Transfer",
      title_number: "1",
      items: [
        {
          number: "1.1",
          sentence:
            "Vitrine Wanderlust offers activities for individuals, couples, companies, and groups worldwide. By making a request, you agree to the terms outlined here.",
        },
      ],
    },
    {
      title: "User Responsibilities",
      title_number: "2",
      items: [
        {
          number: "2.1",
          sentence:
            "You agree to provide accurate and complete information when using our services, including your name, email, and payment details.",
        },
        {
          number: "2.2",
          sentence:
            "You are responsible for maintaining the confidentiality of your account and booking information.",
        },
      ],
    },
    {
      title: "Data Collection",
      title_number: "3",
      items: [
        {
          number: "3.1",
          sentence:
            "We collect personal data, including your name, email, payment, and booking information, in accordance with our Privacy Policy.",
        },
        {
          number: "3.2",
          sentence:
            "We also collect non-personal data through the use of cookies for website optimization.",
        },
      ],
    },
    {
      title: "Payments",
      title_number: "4",
      items: [
        {
          number: "4.1",
          sentence:
            "All bookings made through our platform are subject to full payment as per the pricing displayed at the time of booking.",
        },
        {
          number: "4.2",
          sentence:
            "Refund and cancellation policies are specified at the time of booking.",
        },
      ],
    },
    {
      title: "Amendments to Terms",
      title_number: "5",
      items: [
        {
          number: "5.1",
          sentence:
            "We reserve the right to update or modify these terms at any time without prior notice.",
        },
        {
          number: "5.2",
          sentence:
            "It is your responsibility to check this page regularly for updates.",
        },
      ],
    },
    {
      title: "Governing Law",
      title_number: "6",
      items: [
        {
          number: "6.1",
          sentence:
            "These terms and conditions are governed by the laws of Belgium.",
        },
        {
          number: "6.2",
          sentence:
            "Any disputes arising from the use of our services will be handled under the jurisdiction of Belgian courts.",
        },
      ],
    },
    {
      title: "Contact Information",
      title_number: "7",
      items: [
        {
          number: "7.1",
          sentence:
            "For any questions or concerns regarding these Terms & Services, please contact us at Resa@vitrinesrl.com.",
        },
      ],
    },
  ];