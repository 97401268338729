

export const privacyDataItems = [
    {
      number: "1.1",
      sentence:
        "This Privacy Policy outlines how we collect, use, and safeguard your personal data when you use our services through https://activitiesvitrinesrl.com.",
    },
    {
      number: "1.2",
      sentence:
        "By using our services, you agree to the collection and use of information in accordance with this policy.",
    },
    {
      number: "2.1",
      sentence:
        "We collect personal data such as your name, email, payment information, and booking details for processing your bookings.",
    },
    {
      number: "2.2",
      sentence:
        "We also collect non-personal data through the use of web cookies to optimize your browsing experience on our website.",
    },
    {
      number: "3.1",
      sentence:
        "The personal data we collect is used solely for the purpose of processing your bookings and ensuring a smooth and reliable service.",
    },
    {
      number: "4.1",
      sentence:
        "We do not share your personal data with any third parties. Your information is kept confidential and used only by Vitrine Mobility for the stated purpose.",
    },
    {
      number: "5.1",
      sentence:
        "We do not knowingly collect or solicit any personal information from children. If you believe we have unintentionally collected such information, please contact us at Resa@vitrinesrl.com.",
    },
    {
      number: "6.1",
      sentence:
        "Our website uses cookies to collect non-personal data, enhancing user experience and optimizing website performance. You can disable cookies through your browser settings.",
    },
    {
      number: "7.1",
      sentence:
        "We may update this Privacy Policy from time to time without prior notice. Please check this page regularly for any changes.",
    },
    {
      number: "8.1",
      sentence:
        "If you have any questions or concerns regarding this Privacy Policy, please contact us at Resa@vitrinesrl.com.",
    },
  ];