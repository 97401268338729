// hooks/useVerifyToken.js

import { useEffect, useState, useCallback } from "react";
import axios from "axios";

const useVerifyToken = () => {
  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // to track loading state

  const login = (user, token) => {
    localStorage.setItem("accessToken", token);
    setUser(user); // Set user data
    setAuthenticated(true); // Update authenticated status
  };

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken"); // Remove the token from localStorage
    setUser(null); // Reset user state
    setAuthenticated(false); // Update authentication state
    setIsLoading(false);
    console.log("User logged out successfully.");
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      console.log("Checking localStorage for token:", token);

      if (!token) {
        logout(); // Use the logout function if no token is found
        return;
      }

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/token/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { success, user } = data;

        if (success) {
          console.log("User is authenticated");
          setUser(user);
          setAuthenticated(true);
        } else {
          console.log("User is not authenticated");
          logout(); // Use the logout function if authentication fails
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        logout(); // Use the logout function on any error
      }
      setIsLoading(false);
    };

    verifyToken();
  }, [logout]);

  return { user, authenticated, isLoading, logout, login };
};

export default useVerifyToken;
