import React, { useState } from 'react'
import FAQItem from './FAQItem'
import { useTranslation } from 'react-i18next';
import config from '../../config';
const FAQlist = () => {
    const primaryColor = config.env.primaryColor || "#00df9a";
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    const faqData = [
        {
          question: t('Question.q_1'),
          answer: t('Question.a_1')
        },
        {
          question: t('Question.q_2'),
          answer: t('Question.a_2')
        },
        {
          question: t('Question.q_3'),
          answer: t('Question.a_3')
        },
        {
          question: t('Question.q_4'),
          answer: t('Question.a_4')
        },
        {
          question: t('Question.q_5'),
          answer: t('Question.a_5')
        },
        {
          question: t('Question.q_6'),
          answer: t('Question.a_6')
        },
        {
          question: t('Question.q_7'),
          answer: t('Question.a_7')
        },
        {
          question: t('Question.q_8'),
          answer: t('Question.a_8')
        },
        {
          question: t('Question.q_9'),
          answer: t('Question.a_9')
        },
        {
          question: t('Question.q_10'),
          answer: t('Question.a_10')
        },
        {
          question: t('Question.q_11'),
          answer: t('Question.a_11')
        },
        {
          question: t('Question.q_12'),
          answer: t('Question.a_12')
        },
        {
          question: t('Question.q_13'),
          answer: t('Question.a_13')
        },
        {
          question: t('Question.q_14'),
          answer: t('Question.a_14')
        },
        {
          question: t('Question.q_15'),
          answer: t('Question.a_15')
        }
      ];
     
    const displayedFAQs = showAll ? faqData : faqData.slice(0, 5);
      
    return (
        <div id="faq" className='w-full bg-white py-16 px-4'>
            <div className='max-w-[1240px] mx-auto my-2'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>{t('FAQ.main')} </h1>
                <p>{t('FAQ.subtitle')}</p>
            </div>
            <div className='max-w-[1240px] mx-auto'>
                {displayedFAQs.map( (faq,index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
            </div>
            <div className='max-w-[1240px] mx-auto my-2'>
                {!showAll && (
                    <button className='hover:bg-indigo-400 hover:text-black bg-black w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3' style={{ color: primaryColor }} onClick={() => setShowAll(true)}>{t('Button.see_more')}...</button>
                )}
                {showAll && (
                    <button className='hover:bg-indigo-400 hover:text-black bg-black w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 ' style={{ color: primaryColor }} onClick={() => setShowAll(false)}>{t('Button.see_less')}...</button>
                )}
            </div>
        </div>
    )
}

export default FAQlist
