import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const HowTo = () => {
  const { t } = useTranslation();
  const phone = config.env.phone || "To define";
  const email = config.env.email || "To define";

  const primaryColor = config.env.primaryColor || "#00df9a";

  return (
    <div id="about" className="w-full bg-white py-5 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3">
        <div className="flex flex-col justify-center sm:justify-between mx-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("HowTo.main_1")}
          </h1>
          <p className="lg:text-2xl ">{t("HowTo.p_11")}</p>
          <p className="lg:text-2xl mt-4">{t("HowTo.p_12")}</p>
          <p className="lg:text-2xl mt-4">{t("HowTo.p_13")}</p>
          <Link to="/booking">
            <button className="bg-black w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 hover:bg-indigo-400 hover:text-black" style={{ color: primaryColor }}>
              {t("Button.get_price")}
            </button>
          </Link>
        </div>
        <div className="flex flex-col justify-center sm:justify-between mx-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("HowTo.main_2")}
          </h1>
          <p className="lg:text-2xl ">{t("HowTo.p_21")}</p>
          <p className="lg:text-2xl mt-4">{t("HowTo.p_22")}:</p>
          <p className="lg:text-2xl mt-4">
            <strong>{email}</strong> {t("HowTo.p_23")}
          </p>
        </div>
        <div className="flex flex-col justify-center sm:justify-between mx-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("HowTo.main_3")}
          </h1>
          <p className="lg:text-2xl ">{t("HowTo.p_31")}</p>
          <p className="lg:text-2xl mt-4">
            {t("HowTo.p_32")} : <strong>{phone}</strong>.{" "}
          </p>
          <p className="lg:text-2xl mt-4">{t("HowTo.p_33")}</p>
        </div>
      </div>
    </div>
  );
};

export default HowTo;
