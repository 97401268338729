import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../config";

const AboutUs = () => {
  const { t } = useTranslation();
  const ASSETS_DIR = config.env.img_dir || "assets";

  const path = require(`../${ASSETS_DIR}/aboutus.jpg`);
  const primaryColor = config.env.primaryColor || "#00df9a";

  return (
    <div id="about" className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className="object-cover w-full h-full" src={path} alt="about us" />
        <div className="flex flex-col justify-center sm:justify-between mx-4">
          <p className="font-bold" style={{ color: primaryColor }}>{t("AboutUs.uptitle")}</p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("AboutUs.main")}
          </h1>
          <p className="lg:text-2xl ">{t("AboutUs.p_1")}</p>
          <p className="lg:text-2xl mt-4">{t("AboutUs.p_2")}</p>
          <p className="lg:text-2xl mt-4">{t("AboutUs.p_3")}</p>
          <ul className="lg:text-2xl list-disc">
            <li className="mx-4">{t("AboutUs.l_1")}</li>
            <li className="mx-4">{t("AboutUs.l_2")}</li>
            <li className="mx-4">{t("AboutUs.l_3")}</li>
            <li className="mx-4">{t("AboutUs.l_4")}</li>
          </ul>
          <Link to="/booking">
            <button className="bg-black w-[200px] rounded-md font-medium my-6 md:mx-0 mx-auto py-3 hover:bg-indigo-400 hover:text-black" style={{ color: primaryColor }}>
              {t("Button.now_book")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
