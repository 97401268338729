import React  from 'react'

import { BiSolidTree } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from "../config";

const Hero = () => {
    const { t  } = useTranslation();

    let to=t('Hero.between_1')
    let from=t('Hero.between_2')

    const primaryColor = config.env.primaryColor || "#00df9a";

    return (
        <div id="home" className='text-white'>
            <div className='max-w-[900px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className="font-bold p-2 " style={{ color: primaryColor }}>{t('Hero.uptitle')}</p>
                <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 '> {t('Hero.main_1')}</h1>
                <p className='md:text-2xl text-xl font-bold text-gray-500 pt-4'>{t('Hero.subtitle')}</p>
                <div className='mt-3'>
                    <Link to="/booking">
                        <button className="w-[265px] text-center rounded-full font-medium sm:mr-3 mx-auto px-5 py-3 text-black hover:bg-indigo-400" style={{ backgroundColor: primaryColor }}>
                            {t('Hero.button_1')}<span className='inline-flex'> {'-->'}<BiSolidTree  size={20}/> </span>
                        </button>
                    </Link>
                </div>
                
            </div>
        </div>
    )
}

export default Hero
