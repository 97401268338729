import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";

export const CheckboxSection = ({ title, items, formKey, showInitial = 2, handleChange }) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const primaryColor = config.env.primaryColor || "#00df9a";

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="border-b border-white/10 pb-12">
      <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">{t(title)}</h2>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        {(showAll ? items : items.slice(0, showInitial)).map((item) => (
          <div className="sm:col-span-6" key={item}>
            <label className="block text-xl font-medium text-white">
              <input
                type="checkbox"
                name={formKey}
                value={item}
                onChange={handleChange}
                className="mr-2"
              />
              {t(`MainForm.${item.replace(/\s+/g, "").toLowerCase()}`)}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button
          type="button"
          onClick={toggleShowAll}
          className="hover:underline text-xl"
          style={{ color: primaryColor }}
        >
          {showAll ? t("MainForm.seeLess") : t("MainForm.seeMore")}
        </button>
      </div>
    </div>
  );
};

export const RadioSection = ({ title, items, formKey, showInitial = 2, handleChange}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const primaryColor = config.env.primaryColor || "#00df9a";

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="border-b border-white/10 pb-12">
      <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">{t(title)}</h2>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        {(showAll ? items : items.slice(0, showInitial)).map((item) => (
          <div className="sm:col-span-6" key={item}>
            <label className="block text-xl font-medium text-white">
              <input
                type="radio"
                name={formKey}
                value={item}
                onChange={handleChange}
                className="mr-2"
              />
              {t(`MainForm.${item.replace(/\s+/g, "").replace(/[€+]/g, "").toLowerCase()}`)}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button
          type="button"
          onClick={toggleShowAll}
          className="hover:underline text-xl"
          style={{ color: primaryColor }}
        >
          {showAll ? t("MainForm.seeLess") : t("MainForm.seeMore")}
        </button>
      </div>
    </div>
  );
};
