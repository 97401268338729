import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../config";

const Booking = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const participantsType = searchParams.get("participantsType") || ""; // Default to empty if not provided
  const numParticipants = searchParams.get("numParticipants") || ""; // Default to an empty string if not provided
  const format = searchParams.get("format") || "Stay"; // Default to an empty string if not provided


  return (
    <div className="w-full py-16 px-4">
      <config.pricing_component
        participantsType={participantsType}
        numParticipants={numParticipants}
        format={format}
      />
    </div>
  );
};

export default Booking;
